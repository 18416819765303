import { windowSizeModule } from "./modules/windowSizeMoule";
import { createStore } from "vuex";
import { mainMenuModule } from "./modules/mainMenuModule";
import { backButtonModule } from "./modules/backButtonModule";
import { aboutModule } from "./modules/aboutModule";
import { nextButtonModule } from "./modules/nextButtonModule";
import { possibilitiesModule } from "./modules/possibilitiesModule";
import { whoNeedsModule } from "./modules/WhoNeedsModule";
import { benefitModule } from "./modules/benefitModule";
import { supportModule } from "./modules/supportModule";
import { howToBuyModule } from "./modules/howToBuyModule";
import { teamModule } from "./modules/teamModule";

export default createStore({
  modules: {
    mainMenu: mainMenuModule,
    windowSize: windowSizeModule,
    backButton: backButtonModule,
    nextButton: nextButtonModule,
    about: aboutModule,
    possibilities: possibilitiesModule,
    whoNeeds: whoNeedsModule,
    benefit: benefitModule,
    support: supportModule,
    howToBuy: howToBuyModule,
    team: teamModule,
  },
});
