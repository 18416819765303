import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: () => import("@/components/appLayOut/AppLayOut.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
      }),
    },
    {
      path: "/about",
      component: () => import("@/components/desktop/about/AboutPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 0,
        nextRoute: store.getters.getRouterFromIndex(1),
      }),
    },
    {
      path: "/possibilities",
      component: () =>
        import("@/components/desktop/possibilities/PossibilitiesPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 1,
        nextRoute: store.getters.getRouterFromIndex(2),
      }),
    },
    {
      path: "/whoNeeds",
      component: () => import("@/components/desktop/whoNeeds/WhoNeedsPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 2,
        nextRoute: store.getters.getRouterFromIndex(3),
      }),
    },
    {
      path: "/benefit",
      component: () => import("@/components/desktop/benefit/BenefitPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 3,
        nextRoute: store.getters.getRouterFromIndex(4),
      }),
    },
    {
      path: "/support",
      component: () => import("@/components/desktop/support/SupportPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 4,
        nextRoute: store.getters.getRouterFromIndex(5),
      }),
    },
    {
      path: "/documentation",
      component: () =>
        import("@/components/desktop/documentation/DocumentationPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 5,
        nextRoute: store.getters.getRouterFromIndex(6),
      }),
    },
    {
      path: "/documentation",
      component: () =>
        import("@/components/desktop/documentation/DocumentationPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 5,
        nextRoute: store.getters.getRouterFromIndex(6),
      }),
    },
    {
      path: "/howToBuy",
      component: () => import("@/components/desktop/howToBuy/HowToBuyPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 6,
        nextRoute: store.getters.getRouterFromIndex(7),
      }),
    },
    {
      path: "/team",
      component: () => import("@/components/desktop/team/TeamPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 7,
        nextRoute: store.getters.getRouterFromIndex(8),
      }),
    },
    {
      path: "/contacts",
      component: () => import("@/components/desktop/contacts/ContactsPage.vue"),
      props: () => ({
        blockGap: store.getters.getBlockGap,
        pageIndex: 8,
        nextRoute: store.getters.getRouterFromIndex(0),
      }),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
