import { Module } from "vuex";

interface IHowToBuyState {
  mainText: String;
  textArr: string[];
  mobileTextArr: string[];
  telephone: string;
}

export const howToBuyModule: Module<IHowToBuyState, any> = {
  state: () => ({
    mainText:
      "Права на использование платформы предоставляются на условиях правообладателя — ООО\u00A0«Ай\u2011Теко\u00A0ВЦ» по запросу. Стоимость рассчитывается индивидуально и зависит\n от\u00A0масштаба ИТ-инфраструктуры",
    textArr: [
      "Звоните нам с\u00A010.00\u00A0до\u00A018.00:",
      "+7 985 438 82 32",
      "Пишите коммерческиму\n отделу:",
      "themisoft@i-teco.ru",
      "Если вы хотите расчитать стоимость THEMISoft\n или посмотреть работу на\u00A0демо\u2011стенде, \nнапишите нам:",
    ],

    mobileTextArr: [
      "Звоните нам с\u00A010.00\u00A0до\u00A018.00:\n",
      "Пишите коммерческиму отделу:\nthemisoft@i-teco.ru",
      "Если вы хотите расчитать стоимость THEMISoft или посмотреть работу на\u00A0демо\u2011стенде, напишите нам:",
    ],
    telephone: "+7\u00A0985\u00A0438\u00A082\u00A032",
  }),
  mutations: {},
  getters: {
    getHowToBuyTextArray: (state) => state.textArr,
    getHowToBuyMainText: (state) => state.mainText,
    getMobileHowToBuyMainText: (state) => state.mainText.replace("\n", ""),
    getMobileHowToBuyTextArr: (state) => state.mobileTextArr,
    getPhone: (state) => state.telephone,
  },
};
