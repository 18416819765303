import { Module } from "vuex";

interface IWindowSizeState {
  width: number;
  height: number;
  blockGap: string;
  fontSize: string;
  isMobile: boolean;
}

export const windowSizeModule: Module<IWindowSizeState, any> = {
  state: () => ({
    width: 0,
    height: 0,
    blockGap: "125vw",
    fontSize: "1.04166667vw",
    isMobile: false,
  }),

  mutations: {
    setWindowSize(state, { width, height }) {
      state.width = width;
      state.height = height;
      state.blockGap = "1.25vw";
      // state.fontSize = "1.04651163vw";
      state.fontSize = "1.04651163vw";
      // state.width / state.height > 2.1 ?  : "1.04166667vw";
    },
    setIsMobile(state, value: boolean) {
      state.isMobile = value;
    },
  },

  getters: {
    windowWidth: (state) => state.width,
    windowHeight: (state) => state.height,
    getBlockGap: (state) => state.blockGap,
    getIsMobile: (state) => state.isMobile,
  },
};
