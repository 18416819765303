import { Module } from "vuex";

interface INextButtonModule {
  isHovered: boolean;
}

export const nextButtonModule: Module<INextButtonModule, any> = {
  state: () => ({
    isHovered: false,
  }),
  mutations: {
    setIsHoveredNextButton(state, value: boolean) {
      state.isHovered = value;
    },
  },
  getters: {
    getIsHoveredNextButton: (state) => state.isHovered,
  },
};
