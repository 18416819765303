import { Module } from "vuex";

interface ITeamState {
  arr1: String[];
  arr2: String[];
  mobileText: string[];
}

export const teamModule: Module<ITeamState, any> = {
  state: () => ({
    arr1: [
      "«Айтеко» входит в ТОП\u20115 крупнейших ИТ\u2011компаний\n Россиии и\u00A0в\u00A0перечень системообразующих\n организаций российской экономики\n в сфере информации\u00A0и\u00A0связи",
      "«Айтеко» работает на ИТ\u2011рынке с 1997 года в сфере\n строительства ИТ\u2011инфраструктуры, консалтинга\n и\u00A0разработки, комплексной безопасности,\n аутсорсинга и\u00A0развития стартапов",
    ],
    arr2: [
      "Для работы над\u00A0платформой THEMISoft мы собрали сплоченную и\u00A0профессиональную команду специалистов, на\u00A0протяжении долгих лет внедряющих системы дискаверинга, мониторинга, автоматизации.",
    ],
    mobileText: [
      "«Айтеко» входит в ТОП\u20115 крупнейших ИТ\u2011компаний Россиии и\u00A0в\u00A0перечень системообразующих организаций российской экономики в\u00A0сфере информации\u00A0и\u00A0связи",
      "«Айтеко» работает на ИТ\u2011рынке с\u00A01997 года в\u00A0сфере строительства ИТ\u2011инфраструктуры, консалтинга и\u00A0разработки, комплексной безопасности, аутсорсинга и\u00A0развития стартапов",
      "Для работы над\u00A0платформой THEMISoft мы собрали сплоченную и\u00A0профессиональную команду специалистов, на\u00A0протяжении долгих лет внедряющих системы дискаверинга, мониторинга, автоматизации.",
    ],
  }),
  mutations: {},
  getters: {
    getTeamTextArrayFirst: (state) => state.arr1,
    getTeamTextArraySecond: (state) => state.arr2,
    getTeeamTextMobileArr: (state) => state.mobileText,
  },
};
