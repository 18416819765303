import { Module } from "vuex";

interface IWhoNeedsState {
  headerTextArr: String[];
  textArr: String[][];
  mobileText: String[];
}

export const whoNeedsModule: Module<IWhoNeedsState, any> = {
  state: () => ({
    headerTextArr: [
      "Вам нужна платформа THEMISoft, \nесли перед вами стоит задача:",
      "Вам нужна платформа THEMISoft, \nесли в вашей компании:",
      "Вам нужна платформа THEMISoft, \nесли у вас уже есть:",
    ],
    textArr: [
      [
        "Выстроить систему управления ИТ-инфраструктурой",
        "Повысить прозрачность расходов\n на ИТ-инфраструктуру и качество контроля",
        "Отчитываться о целесообразности потраченных\n средств на ИТ-инфраструктуру",
        "Снизить затраты на контроль за ИТ-инфраструктурой",
      ],
      [
        "Большое количество ИТ-подразделений ",
        "Осуществляются частые изменения\n в ИТ-инфраструктуре",
        "Осуществляются крупные или регулярные\n инвестиции в ИТ-оборудование",
      ],
      [
        "Другая система дискаверинга, но она\n не справляется с поставленной задачей",
        "Желание максимально автоматизировать\n и оптимизировать мониторинг",
        "Желание максимально автоматизировать\n и оптимизировать процесс управления\n конфигурациями",
        "«Лоскутная» автоматизация с множеством различных\n связей, о которых вы хотите знать всё",
      ],
    ],
    mobileText: [
      "Вам нужна платформа THEMISoft, \nесли перед вами стоит задача:",
      "Выстроить систему управления\n ИТ-инфраструктурой",
      "Повысить прозрачность расходов\n на ИТ-инфраструктуру и качество\n контроля",
      "Отчитываться о\u00A0целесообразности\n потраченных средств\n на\u00A0ИТ-инфраструктуру",
      "Снизить затраты на\u00A0контроль\n за\u00A0ИТ-инфраструктурой",

      "Вам нужна платформа THEMISoft, \nесли в\u00A0вашей компании:",
      "Большое количество\n ИТ-подразделений ",
      "Осуществляются частые изменения\n в\u00A0ИТ-инфраструктуре",
      "Осуществляются крупные или\n регулярные инвестиции\n в\u00A0ИТ-оборудование",

      "Вам нужна платформа THEMISoft, \nесли у вас уже есть:",
      "Другая система дискаверинга, но\u00A0она\u00A0не\u00A0справляется с\u00A0поставленной задачей",
      "Желание максимально\n автоматизировать и\u00A0оптимизировать мониторинг",
      "Желание максимально\n автоматизировать и\u00A0оптимизировать\n процесс управления конфигурациями",
      "«Лоскутная» автоматизация\n с множеством различных связей, \nо которых вы хотите знать всё",
    ],
  }),
  mutations: {},
  getters: {
    getWhoNeedsTextArr: (state) => state.textArr,
    getWhoNeedsHeaderTextArr: (state) => state.headerTextArr,
    getWhoNeedsMobileTextArr: (state) => state.mobileText,
  },
};
