import { Module } from "vuex";

interface IAboutState {
  textArr: String[];
  mobileTextArr: String[];
}

export const aboutModule: Module<IAboutState, any> = {
  state: () => ({
    textArr: [
      "Автоматизированное обнаружение конфигурационных единиц и их взаимосвязей",
      "Реконсиляция данных, полученных из различных источников различными методами",
      "Автоматический аудит и актуализация данных\nо ландшафте ИТ-инфраструктуры и ИТ-систем",
      "Решение на базе собственной разработки \n «Ай-Теко ВЦ» позволяет исключить зависимость\nот зарубежных вендоров",
      "Запись в едином реестре российского ПО № 18888",
    ],
    mobileTextArr: [
      "Автоматизированное обнаружение\n конфигурационных единиц\n и их взаимосвязей",
      "Реконсиляция данных, полученных\n из различных источников\n различными методами",
      "Автоматический аудит\n и актуализация данных\nо ландшафте ИТ-инфраструктуры\n и ИТ-систем",
      "Решение на базе собственной \nразработки  «Ай-Теко ВЦ» позволяет\n исключить зависимость\nот зарубежных вендоров",
      "Запись в едином реестре\n российского ПО № 18888",
    ],
  }),
  mutations: {},
  getters: {
    getAboutTextArray: (state) => state.textArr,
    getAboutMobileTextArray: (state) => state.mobileTextArr,
  },
};
