import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

const initialWidth = window.innerWidth;
store.commit("setIsMobile", initialWidth < 481);

window.addEventListener("DOMContentLoaded", () => {
  store.commit("setWindowSize", {
    width: window.innerWidth,
    height: window.innerHeight,
  });
});

window.addEventListener("resize", () => {
  store.commit("setWindowSize", {
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const initialWidth = window.innerWidth;
  store.commit("setIsMobile", initialWidth < 481);
});

createApp(App).use(store).use(router).mount("#app");
