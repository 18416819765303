import { Module } from "vuex";

interface IMainMenuState {
  menuBlockTextArr: string[];
  menuBlockHoveredTextArr: string[][];
  menuThemiBlockText: string;
  hoveredItemsValues: Boolean[];
  routerPages: String[];
}

export const mainMenuModule: Module<IMainMenuState, any> = {
  state: () => ({
    menuBlockTextArr: [
      "THEMISoft",
      "Возможности",
      "Кому нужна\nплатформа",
      "Выгода\nот внедрения",
      "Поддержка",
      "Документация",
      "Как купить",
      "Команда",
      "Контакты",
    ],
    routerPages: [
      "/about",
      "/possibilities",
      "/whoNeeds",
      "/benefit",
      "/support",
      "/documentation",
      "/HowToBuy",
      "/team",
      "/contacts",
    ],
    menuBlockHoveredTextArr: [
      [
        "Платформа для автоматизированного сбора инвентаризационных\nи топологических данных об ИТ-инфраструктуре",
      ],
      [
        "Автоматизированное обнаружение конфигурационных единиц\nи их взаимосвязей;",
        "Простое подключение готовых модулей обнаружения;",
        "Объединение и проверка целостности данных, полученных\nиз разных источников;",
        "Автоматическая актуализация данных о ландшафте\nИТ-инфраструктуры;",
        "Интеграция со смежными ИТ-системами",
      ],
      [
        "Компании с развитой ИТ-инфраструктурой, состоящей из тысяч\nИТ-устройств (роутеры, компьютеры, сервера, IP-камеры и т.д.)",
      ],
      [
        "Экономическая эффективность, повышение качества ИТ-услуг\nи контроль безопасности",
      ],
      ["Можем выбрать формат поддержки, где мы будем на связи 24/7"],
      ["Руководство пользователя;", "Инструкция по инсталяции"],
      [
        "Приобрести, получить пробную версию, расчитать стоимость или\nзадать другой вопрос",
      ],
      ["Наша команда разработчиков THEMISoft"],
      ["Контакты правообладателя — ООО «Ай-Теко ВЦ»"],
    ],
    menuThemiBlockText: "разумный\n подход\n к управлению ИТ",
    hoveredItemsValues: Array(9).fill(false),
  }),
  mutations: {
    setHoveredItemTrue: (state, index: number) => {
      state.hoveredItemsValues = state.hoveredItemsValues.map(
        (value, i) => i === index
      );
    },
    setHoveredItemFalse: (state) => {
      state.hoveredItemsValues.fill(false);
    },
  },
  actions: {},
  getters: {
    getAllTitles: (state) => {
      return state.menuBlockTextArr;
    },
    getTitleFromIndex: (state) => (index: number) => {
      return state.menuBlockTextArr[index];
    },
    getThemiBlockText: (state) => {
      return state.menuThemiBlockText;
    },
    getAllHoveredText: (state) => {
      return state.menuBlockHoveredTextArr;
    },
    getHoveredItemValue: (state) => (index: number) => {
      return state.hoveredItemsValues[index];
    },
    getAllrouterPages: (state) => {
      return state.routerPages;
    },
    getRouterFromIndex: (state) => (index: number) => {
      return state.routerPages[index];
    },
  },
};
