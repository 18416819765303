import { Module } from "vuex";

interface IBenefitState {
  headerTextArr: String[];
  textArr: String[][];
  mobileText: String[];
}

export const benefitModule: Module<IBenefitState, any> = {
  state: () => ({
    headerTextArr: [
      "Повышение экономической эффективности\n управления ИТ:",
      "Повышение качества ИТ-услуг:",
      "Повышение контроля безопасности:",
    ],
    textArr: [
      [
        "Предотвращение потерь активов",
        "Снижение затрат на поддержку ИТ-инфраструктуры",
        "Сокращение затрат на оборудование",
        "Оптимизация планирования инвестиций",
      ],
      [
        "Снижение рисков, связанных с изменениями",
        "Повышение прозрачности, контроля и управления\n ИТ-процессами",
      ],
      ["Снижение рисков нарушения безопасности"],
    ],
    mobileText: [
      "Повышение экономической\n эффективности управления ИТ:",
      "Предотвращение потерь активов",
      "Снижение затрат на поддержку\n ИТ-инфраструктуры",
      "Сокращение затрат на оборудование",
      "Оптимизация планирования\n инвестиций",

      "Повышение качества ИТ-услуг:",
      "Снижение рисков, связанных\n с изменениями",
      "Повышение прозрачности, контроля\n и управления ИТ-процессами",

      "Повышение контроля безопасности:",
      "Снижение рисков нарушения\n безопасности",
    ],
  }),
  mutations: {},
  getters: {
    getBenefitTextArr: (state) => state.textArr,
    getBenefitHeaderTextArr: (state) => state.headerTextArr,
    getBenefitMobileTextArr: (state) => state.mobileText,
  },
};
