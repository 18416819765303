import { Module } from "vuex";

interface ISupportState {
  headerTextArr: String[];
  textArr: String[][];
}

export const supportModule: Module<ISupportState, any> = {
  state: () => ({
    headerTextArr: ["", "Тариф «Стандартный»", "Тариф «Премиум»"],
    textArr: [
      [
        "Количество инцидентов за год",
        "Доступность поддержки",
        "Гарантированное время ответа",
        "Доступ к новым адаптерам опроса инфраструктуры",
        "Прямой контакт с\u00A0разработчиком системы\n для\u00A0срочных вопросов",
        "Время реагирования в\u00A0черезвычайной ситуации",
        "Приоритетное предоставление исправлений в\u00A0случае\n критичных поломок",
        "Личный менеджер по\u00A0всем вопросам",
        "Приоритет в\u00A0заявках на\u00A0корректировку RoadMap",
      ],
      [
        "12",
        "8 x 5",
        "24 часа",
        "Предусмотрен",
        "Не предусмотрен\n\n",
        "Не лимитировано",
        "Не предусмотрено\n\n",
        "Не предусмотрен",
        "Не предусмотрен",
      ],
      [
        "Неограниченное",
        "24 х 7",
        "2 часа",
        "Предусмотрен",
        "Предусмотрен\n\n",
        "60 минут",
        "Предусмотрено\n\n",
        "Предусмотрен",
        "Предусмотрен",
      ],
    ],
  }),
  mutations: {},
  getters: {
    getSupportTextArr: (state) => state.textArr,
    getSupportHeaderTextArr: (state) => state.headerTextArr,
  },
};
