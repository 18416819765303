import { Module } from "vuex";

interface IBackButtonState {
  isHovered: boolean;
}

export const backButtonModule: Module<IBackButtonState, any> = {
  state: () => ({
    isHovered: false,
  }),
  mutations: {
    setIsHoveredBackButton(state, value: boolean) {
      state.isHovered = value;
    },
  },
  getters: {
    getIsHoveredBaackButton: (state) => state.isHovered,
  },
};
