<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
  setup() {
    return {};
  },
});
</script>

<style>
body,
html {
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0;
  background: black;
}

* ::-webkit-scrollbar {
  display: none;
}

* {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

svg {
  pointer-events: none;
}
</style>
