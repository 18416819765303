import { Module } from "vuex";

interface IPossibilitiesState {
  mainText: String;
  textArr: String[];
  mobileText: String[];
}

export const possibilitiesModule: Module<IPossibilitiesState, any> = {
  state: () => ({
    mainText:
      "Платформа дискаверинга THEMISoft служит единым поставщиком данных для систем потребителей и является ядром системы управления ИТ-инфраструктурой:",
    textArr: [
      "Собирает и передает актуальные данные\n об ИТ-инфраструктуре системам мониторинга,\nITAM и ITSM",
      "Находит резервы ресурсов на вашем оборудовании",
      "Позволяет строить сервисно\u2011ресурсную модель (СРМ)\u00A0между элементами инфраструктуры и\u00A0бизнес\u2011приложениями",
      "Обнаруживает программное обеспечение и\u00A0конфигурации, которые могут нарушать требования безопасности",
    ],
    mobileText: [
      "Платформа дискаверинга THEMISoft\n служит единым поставщиком\n данных для систем потребителей\n и является ядром системы\n управления ИТ-инфраструктурой:",
      "Собирает и\u00A0передает актуальные\n данные об ИТ-инфраструктуре\n системам мониторинга, ITAM\u00A0и\u00A0ITSM",
      "Находит  резервы ресурсов\n на вашем оборудовании",
      "Позволяет строить сервисно-ресурсную модель (СРМ) между элементами инфраструктуры и\u00A0бизнес\u2011приложениями",
      "Обнаруживает программное обеспечение и\u00A0конфигурации, которые могут нарушать требования безопасности",
    ],
  }),
  mutations: {},
  getters: {
    getPosibilitiesTextArray: (state) => state.textArr,
    getPosibilitiesMainText: (state) => state.mainText,
    getPossibilitiesMobileTextArray: (state) => state.mobileText,
  },
};
